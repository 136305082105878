import React, { useState } from "react";
import { GitPullRequest, AlertOctagon, RefreshCcw, GitPullRequestClosed } from "lucide-react";
import Fuse from "fuse.js";
import { MergeRequest } from "./MergeRequest";
import { PaginationButtons } from "./PaginationButtons";
import { CollapsibleSection } from "./CollapsibleSection";
import { useAuth } from "../AuthContext";
import { ITEMS_PER_PAGE } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useMergeRequests } from "../hooks/useMergeRequests";
import { useRecentPushes } from "../hooks/useRecentPushes";
import { Avatar } from "./Avatar";
import { FilterButton } from "./FilterButton";
import { SearchBar } from "./SearchBar";
import { CountChip } from "./CountChip";
import { RecentPushes } from "./RecentPushes";

export const Dashboard = () => {
  const { user, logout } = useAuth();
  const [refreshing, setRefreshing] = useState(false);
  const [pendingReviewPage, setPendingReviewPage] = useState(1);
  const [myOpenPage, setMyOpenPage] = useState(1);
  const [recentlyClosedPage, setRecentlyClosedPage] = useState(1);
  const [showDrafts, setShowDrafts] = useLocalStorage("showDrafts", true);
  const [showBotPRs, setShowBotPRs] = useLocalStorage("showBotPRs", true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const { pendingReviewMRs, myOpenMRs, recentlyClosedMRs, loading, loadingClosed, error, refetch, userAvatar } =
    useMergeRequests();

  const { pushesWithoutMR } = useRecentPushes();

  const refreshData = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const sortedPendingReviewMRs = [...pendingReviewMRs].sort((a, b) => {
    const aReviewers = a.reviewers?.nodes || [];
    const bReviewers = b.reviewers?.nodes || [];
    const currentUsername = user?.profile?.username;

    const aApproved = aReviewers.some((r) => r.username === currentUsername && r.approved);
    const bApproved = bReviewers.some((r) => r.username === currentUsername && r.approved);

    if (a.draft !== b.draft) return a.draft ? 1 : -1;
    if (!a.draft && !b.draft) {
      if ((a.author?.bot ?? false) !== (b.author?.bot ?? false)) {
        return a.author?.bot ?? false ? 1 : -1;
      }
    }
    if (aApproved !== bApproved) return aApproved ? 1 : -1;
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  const fuseOptions = {
    keys: ["title", "author.name", "author.username", "projectPath"],
    threshold: 0.3,
    distance: 100,
  };

  const fusePendingReview = new Fuse(sortedPendingReviewMRs, fuseOptions);
  const fuseMyOpen = new Fuse(myOpenMRs, fuseOptions);
  const fuseRecentlyClosed = new Fuse(recentlyClosedMRs, fuseOptions);

  const filterMRs = (mrs, fuseInstance) => {
    let filtered = mrs.filter((mr) => (showDrafts || !mr.draft) && (showBotPRs || !(mr.author?.bot ?? false)));

    if (searchQuery) {
      const searchResults = fuseInstance.search(searchQuery);
      filtered = searchResults.map((result) => result.item);
    }

    return filtered;
  };

  const filteredPendingReviewMRs = filterMRs(sortedPendingReviewMRs, fusePendingReview);
  const filteredMyOpenMRs = filterMRs(myOpenMRs, fuseMyOpen);
  const filteredRecentlyClosedMRs = filterMRs(recentlyClosedMRs, fuseRecentlyClosed);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto p-4">
          <div className="bg-white dark:bg-gray-800 rounded shadow p-3 mb-6">
            <div className="flex justify-between items-center gap-4 h-10">
              <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 animate-pulse" />
                <div className="w-32 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              </div>
              <div className="flex-1 mx-4">
                <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              </div>
              <div className="flex items-center gap-2">
                <div className="w-24 h-8 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              </div>
            </div>
          </div>

          <div className="space-y-6">
            {[1, 2, 3].map((section) => (
              <div key={section} className="bg-white dark:bg-gray-800 rounded shadow p-4">
                <div className="flex items-center gap-2 mb-4">
                  <div className="w-8 h-8 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                  <div className="w-48 h-5 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                </div>
                {[1, 2].map((item) => (
                  <div key={item} className="mb-4 py-4 border-b last:border-0">
                    <div className="flex justify-between items-start mb-2">
                      <div className="w-3/4 h-5 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                      <div className="w-16 h-5 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                    </div>
                    <div className="flex gap-2 mt-2">
                      <div className="w-20 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                      <div className="w-24 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto p-4">
        <div className="bg-white dark:bg-gray-800 rounded shadow p-3 mb-6">
          <div className="flex justify-between items-center gap-4 h-10">
            <div className="flex items-center gap-2 shrink-0">
              <Avatar src={userAvatar} alt={user?.profile?.name} size="md" />
              <span className="font-medium text-sm dark:text-white">{user?.profile?.name}</span>
            </div>
            <div className="flex-1 mx-4 min-h-[40px] flex items-center">
              <SearchBar
                searchQuery={searchQuery}
                onSearchChange={setSearchQuery}
                showSearch={showSearch}
                onShowSearch={setShowSearch}
              />
            </div>
            <div className="flex items-center gap-2 shrink-0">
              <FilterButton
                showDrafts={showDrafts}
                showBotPRs={showBotPRs}
                onToggleDrafts={setShowDrafts}
                onToggleBotPRs={setShowBotPRs}
              />
              <RefreshCcw
                className={`${refreshing ? "animate-spin " : ""} transform rotate-180 w-4 h-4 text-gray-500 dark:text-gray-400 cursor-pointer`}
                onClick={refreshData}
              />
              <button
                onClick={logout}
                className="text-sm bg-red-500 text-white rounded px-3 py-1 hover:bg-red-600 transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        </div>

        <RecentPushes pushes={pushesWithoutMR} />

        {error && (
          <div className="bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-800 text-red-700 dark:text-red-200 px-4 py-3 rounded-lg mb-6 shadow-sm">
            <p className="font-medium">Error loading data</p>
            <p className="text-sm">{error}</p>
          </div>
        )}

        <div className="space-y-6">
          <CollapsibleSection
            storageKey="dashboard-pending-review"
            title={
              <>
                Open PRs Pending My Review
                <CountChip count={filteredPendingReviewMRs.length} />
              </>
            }
            icon={AlertOctagon}
            iconColor="text-orange-500"
          >
            {filteredPendingReviewMRs.length > 0 ? (
              <>
                {filteredPendingReviewMRs
                  .slice((pendingReviewPage - 1) * ITEMS_PER_PAGE, pendingReviewPage * ITEMS_PER_PAGE)
                  .map((mr) => (
                    <MergeRequest key={mr.id} mr={mr} />
                  ))}
                <PaginationButtons
                  total={filteredPendingReviewMRs.length}
                  currentPage={pendingReviewPage}
                  onPageChange={setPendingReviewPage}
                  itemsPerPage={ITEMS_PER_PAGE}
                />
              </>
            ) : (
              <div className="bg-blue-50 dark:bg-blue-900/50 text-blue-700 dark:text-blue-200 px-4 py-3 rounded">
                No merge requests pending your review
              </div>
            )}
          </CollapsibleSection>

          <CollapsibleSection
            storageKey="dashboard-my-open"
            title={
              <>
                My Open PRs
                <CountChip count={filteredMyOpenMRs.length} />
              </>
            }
            icon={GitPullRequest}
            iconColor="text-blue-500"
          >
            {filteredMyOpenMRs.length > 0 ? (
              <>
                {filteredMyOpenMRs.slice((myOpenPage - 1) * ITEMS_PER_PAGE, myOpenPage * ITEMS_PER_PAGE).map((mr) => (
                  <MergeRequest key={mr.id} mr={mr} />
                ))}
                <PaginationButtons
                  total={filteredMyOpenMRs.length}
                  currentPage={myOpenPage}
                  onPageChange={setMyOpenPage}
                  itemsPerPage={ITEMS_PER_PAGE}
                />
              </>
            ) : (
              <div className="bg-blue-50 dark:bg-blue-900/50 text-blue-700 dark:text-blue-200 px-4 py-3 rounded">
                You haven't created any open merge requests
              </div>
            )}
          </CollapsibleSection>

          <CollapsibleSection
            storageKey="dashboard-recently-closed"
            title={
              <>
                Recently Closed PRs
                <CountChip count={loadingClosed ? "..." : filteredRecentlyClosedMRs.length} />
              </>
            }
            icon={GitPullRequestClosed}
            iconColor="text-gray-500"
          >
            {loadingClosed ? (
              <div className="h-48 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
            ) : filteredRecentlyClosedMRs.length > 0 ? (
              <>
                {filteredRecentlyClosedMRs
                  .slice((recentlyClosedPage - 1) * ITEMS_PER_PAGE, recentlyClosedPage * ITEMS_PER_PAGE)
                  .map((mr) => (
                    <MergeRequest key={mr.id} mr={mr} />
                  ))}
                <PaginationButtons
                  total={filteredRecentlyClosedMRs.length}
                  currentPage={recentlyClosedPage}
                  onPageChange={setRecentlyClosedPage}
                  itemsPerPage={ITEMS_PER_PAGE}
                />
              </>
            ) : (
              <div className="bg-blue-50 dark:bg-blue-900/50 text-blue-700 dark:text-blue-200 px-4 py-3 rounded">
                No recently closed merge requests
              </div>
            )}
          </CollapsibleSection>
        </div>
      </div>
    </div>
  );
};
