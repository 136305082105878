import React from "react";
import { GitBranch } from "lucide-react";
import { DateTime } from "./DateTime";

const formatProjectName = (reference) => {
  const parts = reference.split("/");
  if (parts.length <= 1) return reference;
  parts.splice(0, 1);

  const projectPart = parts.join("/");
  return projectPart;
};

export const RecentPushes = ({ pushes }) => {
  if (pushes.length === 0) return null;

  return (
    <div className="space-y-2 mb-6">
      {pushes.map((push) => (
        <div
          key={push.id}
          className="bg-blue-50 dark:bg-blue-900/30 border border-blue-200 dark:border-blue-800 text-blue-700 dark:text-blue-200 px-4 py-3 rounded-lg shadow-sm"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <GitBranch className="w-4 h-4" />
              <span>
                You pushed to {" "}
                <a
                  href={`${push.projectInfo.webUrl}/-/tree/${push.push_data.ref}`}
                  className="font-medium hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="font-mono">{push.push_data.ref}</span>
                </a>
                {" "}in{" "}
                <a
                  href={push.projectInfo.webUrl}
                  className="font-medium hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatProjectName(push.projectInfo.fullPath)}
                </a>{" "}
                <DateTime date={push.created_at} />
              </span>
            </div>
            <a
              href={`${push.projectInfo.webUrl}/-/merge_requests/new?merge_request%5Bsource_branch%5D=${encodeURIComponent(push.push_data.ref)}`}
              className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Create merge request
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};
