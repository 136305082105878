import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthContext";
import { GITLAB_BASE_URL, REFRESH_INTERVAL } from "../constants";

const MR_FIELDS = `
  iid
  webPath
  title
  createdAt
  updatedAt
  state
  draft
  reference
  conflicts
  userNotesCount
  author {
    avatarUrl
    name
    username
    bot
  }
  diffStatsSummary {
    additions
    changes
    deletions
  }
  headPipeline {
    active
    latest
    name
    totalJobs
    status
    failedJobs: jobs(statuses: [FAILED, CANCELING, CANCELED]) {
      count
    }
    passedJobs: jobs(statuses: [SUCCESS]) {
      count
    }
  }
  reviewers {
    nodes {
      username
      avatarUrl
      name
      mergeRequestInteraction {
        approved
      }
    }
  }
  project {
    archived
  }
`;

const DISCUSSIONS_FIELDS = `
  discussions {
    nodes {
      resolved
      resolvable
    }
  }
`;

const OPEN_MRS_QUERY = `
  query getOpenMRs {
    currentUser {
      avatarUrl
      username
      reviewRequests: reviewRequestedMergeRequests(state: opened, sort: UPDATED_DESC) {
        nodes {
          ${MR_FIELDS}
          ${DISCUSSIONS_FIELDS}
        }
      }
      openRequests: authoredMergeRequests(state: opened, sort: UPDATED_DESC) {
        nodes {
          ${MR_FIELDS}
          ${DISCUSSIONS_FIELDS}
        }
      }
    }
  }
`;

const CLOSED_MRS_QUERY = `
  query getClosedMRs {
    currentUser {
      mergedRequests: authoredMergeRequests(state: merged, sort: UPDATED_DESC, first: 20) {
        nodes {
          ${MR_FIELDS}
        }
      }
      closedRequests: authoredMergeRequests(state: closed, sort: UPDATED_DESC, first: 20) {
        nodes {
          ${MR_FIELDS}
        }
      }
      mergedReviewRequests: reviewRequestedMergeRequests(state: merged, sort: UPDATED_DESC, first: 20) {
        nodes {
          ${MR_FIELDS}
        }
      }
      closedReviewRequests: reviewRequestedMergeRequests(state: closed, sort: UPDATED_DESC, first: 20) {
        nodes {
          ${MR_FIELDS}
        }
      }
    }
  }
`;

const excludeArchivedProjects = (mr) => !mr.project.archived;
const excludeMyPrs = (mr) => !mr.author.isMe;

export const useMergeRequests = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingClosed, setLoadingClosed] = useState(true);
  const [error, setError] = useState(null);
  const [lastFailedRequest, setLastFailedRequest] = useState(null);
  const [data, setData] = useState({
    pendingReviewMRs: [],
    myOpenMRs: [],
    recentlyClosedMRs: [],
    userAvatar: null,
  });

  const executeQuery = useCallback(
    async (query, variables = {}) => {
      try {
        const response = await fetch(`${GITLAB_BASE_URL}/api/graphql`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.access_token}`,
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        });

        if (!response.ok) {
          setLastFailedRequest({ query, variables });
          throw new Error("GraphQL request failed");
        }

        const result = await response.json();
        if (result.errors) {
          setLastFailedRequest({ query, variables });
          throw new Error(result.errors[0].message);
        }

        setLastFailedRequest(null);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    [user],
  );

  const avatarUrl = useCallback((url) => {
    if (!url) return null;
    return url.startsWith("https://") ? url : `${GITLAB_BASE_URL}${url}`;
  }, []);

  const transformMergeRequest = useCallback(
    (mr) => ({
      id: mr.iid,
      iid: mr.iid,
      webUrl: `${GITLAB_BASE_URL}${mr.webPath}`,
      title: mr.title,
      createdAt: mr.createdAt,
      updatedAt: mr.updatedAt,
      state: mr.state,
      draft: mr.draft,
      reference: mr.reference,
      projectPath: mr.reference?.split("!")[0] || mr.webPath?.split("/-/")[0]?.split("/").slice(-2).join("/") || "",
      userNotesCount: mr.userNotesCount,
      conflicts: mr.conflicts,
      author: {
        name: mr.author.name,
        username: mr.author.username,
        avatarUrl: avatarUrl(mr.author.avatarUrl),
        bot: mr.author.bot || mr.author.username === "renovate",
        isMe: user.profile.username == mr.author.username,
      },
      diffStatsSummary: mr.diffStatsSummary,
      discussions: (mr.discussions?.nodes || []).filter((r) => !r.resolved && r.resolvable).length,
      headPipeline: mr.headPipeline
        ? {
            status: mr.headPipeline.active ? "running" : "success",
            latest: mr.headPipeline.latest,
            name: mr.headPipeline.name,
            totalJobs: mr.headPipeline.totalJobs,
            failedJobs: mr.headPipeline.failedJobs.count,
            passedJobs: mr.headPipeline.passedJobs.count,
          }
        : null,
      reviewers: {
        nodes: (mr.reviewers?.nodes || []).map((reviewer) => ({
          avatarUrl: avatarUrl(reviewer.avatarUrl),
          name: reviewer.name,
          username: reviewer.username,
          approved: reviewer.mergeRequestInteraction?.approved || false,
        })),
      },
      project: mr.project,
    }),
    [avatarUrl, user],
  );

  const fetchOpenData = useCallback(
    async (overrideLoading = true) => {
      try {
        if (overrideLoading) setLoading(true);
        const openResult = await executeQuery(OPEN_MRS_QUERY);

        setData((prevData) => ({
          ...prevData,
          userAvatar: avatarUrl(openResult.currentUser.avatarUrl),
          username: openResult.currentUser.username,
          pendingReviewMRs: openResult.currentUser.reviewRequests.nodes
            .map(transformMergeRequest)
            .filter(excludeArchivedProjects)
            .filter(excludeMyPrs),
          myOpenMRs: openResult.currentUser.openRequests.nodes
            .map(transformMergeRequest)
            .filter(excludeArchivedProjects),
        }));
      } catch (err) {
        setError(err.message);
        console.error("Error fetching open merge requests:", err);
      } finally {
        if (overrideLoading) setLoading(false);
      }
    },
    [executeQuery, avatarUrl, transformMergeRequest],
  );

  const fetchClosedData = useCallback(
    async (overrideLoading = true) => {
      try {
        if (overrideLoading) setLoadingClosed(true);
        const closedResult = await executeQuery(CLOSED_MRS_QUERY);

        const recentlyClosedMRs = [
          ...closedResult.currentUser.mergedRequests.nodes,
          ...closedResult.currentUser.closedRequests.nodes,
          ...closedResult.currentUser.mergedReviewRequests.nodes,
          ...closedResult.currentUser.closedReviewRequests.nodes,
        ]
          .map(transformMergeRequest)
          .filter(excludeArchivedProjects)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .slice(0, 25); // Keep only the 25 most recent

        setData((prevData) => ({
          ...prevData,
          recentlyClosedMRs,
        }));
      } catch (err) {
        console.error("Error fetching closed merge requests:", err);
      } finally {
        if (overrideLoading) setLoadingClosed(false);
      }
    },
    [executeQuery, transformMergeRequest],
  );

  const fetchAllData = useCallback(async () => {
    setError("");
    await Promise.all([fetchOpenData(false), fetchClosedData(false)]);
  }, [fetchOpenData, fetchClosedData]);

  // Retry failed requests when user (token) changes
  useEffect(() => {
    if (user?.access_token && lastFailedRequest) {
      console.log("Retrying failed request with new token");
      const retryRequest = async () => {
        try {
          setError(null);
          if (lastFailedRequest.query === OPEN_MRS_QUERY) {
            await fetchOpenData();
          } else if (lastFailedRequest.query === CLOSED_MRS_QUERY) {
            await fetchClosedData();
          }
        } catch (error) {
          console.error("Retry with new token failed:", error);
          setError(error.message);
        }
      };
      retryRequest();
    }
  }, [user, lastFailedRequest, fetchOpenData, fetchClosedData]);

  // Initial fetch
  useEffect(() => {
    if (user?.access_token) {
      fetchOpenData();
      fetchClosedData();
    }
  }, [user, fetchOpenData, fetchClosedData]);

  // Periodic refresh
  useEffect(() => {
    if (!user?.access_token) return;

    const intervalId = setInterval(() => {
      fetchAllData();
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [user, fetchAllData]);

  return {
    ...data,
    loading,
    loadingClosed,
    error,
    refetch: fetchAllData,
  };
};
